.container-xp {
  text-align: center;
  padding: 20px 0;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .container-xp {
    padding: 20px;
  }
}