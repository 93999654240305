#skills {
  position: relative;
}

#skills::before {
  clip-path: polygon(0 0, 101% 0, 0 100%);
  height:600px;
  width:100%;
  position:relative;
  content:'';
  display:block;
  position:absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(250,112,97,1) 100%);
}

.container-skills {
  text-align: center;
  padding: 20px;
  column-count: 2;
}

@media (min-width: 576px) {
  .container-skills {
    column-count: 3;
  }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .container-skills {
    column-count: 5;
  }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .container-skills {
    column-count: 10;
  }
 }