.main-box {
  margin-top: 56px;
  scroll-behavior: smooth;
  overflow-y: scroll;
}



.box h1,
.title {
  background-color: #fa7061;
  color: #fff;
  padding: 15px 25px 5px;
  border-radius: 5px;
  text-transform: uppercase;
  max-width: 400px;
  margin: auto;
  text-align: center;
  font-weight: bold;
}

 @media (min-width: 576px) {

  .box {
    padding: 3em 2em;
  }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .main-box {
    margin-top: 82px;
     height: calc( 100vh - 82px);
  }
 }