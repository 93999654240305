@font-face {
  font-family: 'Indie-Flower';
  src: url('./../fonts/IndieFlower-Regular.ttf');
}

#presentation {
  padding-top: 15px;
  background: linear-gradient(to bottom,  rgba(52,58,64,1) 0%,rgba(98,99,119,0.75) 75%,rgba(113,112,137,0) 100%);
}



.card-container-img {
  display: flex;
  align-items: center;
  padding: 2em;
}

.card-container-img img {
  padding: 5px;
  border-radius: 50%;
}

.card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

#presentation .card-title {
  font-family: 'Indie-Flower', cursive;
  font-size: 3em;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #343a40;
}

#presentation .card-subtitle {
  font-family: 'Indie-Flower', cursive;
  font-size: 2.5em;
  text-decoration: underline;
  display: flex;
  justify-content: space-around;
}

.card-body ul {
  margin-top: 2em;
  text-align: justify;
}

@media (min-width: 576px) {

  #presentation .card {
    width: 75%;
    margin: auto;
  }

}

