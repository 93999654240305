@font-face {
  font-family: 'Satisfy';
  src: url('../fonts/Satisfy-Regular.ttf');
}

#box-project {
  background-color: rgba(250, 112, 97, 0.25);
}

.subtitle {
  text-decoration: underline;
  font-size: 1.3em;
  font-weight: bold;
  margin-right: 1em;
}

.description {
  text-align: justify;
}

.moreInfos {
  text-align: justify;
  margin-top: 3em;
  background-color: #eae672;
  padding: 10px;
  border-radius: 20px;
  font-family: 'Satisfy', cursive;
  font-size: 22px;
}

.container-projects {
  text-align: center;
  padding: 20px;
  column-count: 1;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .container-projects {
    column-count: 2;
  }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .container-projects {
    column-count: 3;
  }
 }