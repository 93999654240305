#contact {
  background-color: #282c34;
}

.footer-contact ul {
  display: flex;
  justify-content: space-between;
  width: 25%;
  margin: auto;
  font-size: 1.5em;
  list-style: none;
  padding: 0;
}