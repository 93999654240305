.left {
  width: 100%;
}

.right {
  width: 90%;
}

.middle {
  display: none;
}

.xp-content {
  border-radius: 5px;
  background-color: rgba(250, 112, 92, 0.25);
}


.xp-compagny,
.xp-job {
  width: 100%;
  background-color: #fa7061;
  color: #fff;
  padding: 5px;
  font-weight: bold;
  font-size: 1.3em;
  border-radius: 5px 5px 0 0;
}

.xp-box p {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.xp-text p {
  text-align: center;
}

.xp-box p,
.xp-text p {
  width: 100%;
  color: gray;
  margin-bottom: 0;
}

.xp-box img {
  border-radius: 5px;
  max-width: 100px;
}

.xp-compagny,
.xp-box p,
.xp-box img {
  float: right;
}

.xp-text {
  float: left;
  width: 100%;
}

.rod {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rod-one {
  height: 50%;
}

.rod-two {
  height: 100%;
}

.case-left {
  border-right: solid black 2px;
  width: 50%
}

.case-right {
  border-left: solid black 2px;
  width: 50%;
}

.cross {
  background-color: black;
  height: 20px;
  width: 20px;
  color: #fa7061;
  border-radius: 100px;
}

.cross svg {
  width: 20px;
  height: 20px;
  vertical-align: unset;
}

.xp-infos {
  text-align: left;
  padding: 10px 0 10px 20px;
}

.xp-skills {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 2em;
}

.xp-skills .badge {
  font-size: 1em;
  box-shadow: 0 1em 2em grey;
  margin: 1px 4px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .middle {
    width: 20px;
    display: flex;
    flex-direction: column;
  }

  .left {
    margin-right: 30px;
  }

  .right {
    margin-left: 30px;
  }

  .left,
  .right {
    width: 100%;
  }

  .xp-box p {
    justify-content: space-between;
  }
}
