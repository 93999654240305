
#loading {
  background-color: #343A40;
  color: #fa7061;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  text-align: center;
}

#loading div:first-child {
  font-size: 4em;
  font-weight: bold;
}

#fountainG{
	position:relative;
	width:510px;
	height:62px;
}

.fountainG{
	position:absolute;
	top:0;
	background-color:rgba(250,112,97,0.25);
	width:62px;
	height:62px;
	animation-name:bounce_fountainG;
	animation-duration:1.5s;
	animation-iteration-count:infinite;
	animation-direction:normal;
	transform:scale(.3);
	border-radius:40px;
}

#fountainG_1{
	left:0;
	animation-delay:0.6s;
}

#fountainG_2{
	left:64px;
	animation-delay:0.75s;
}

#fountainG_3{
	left:128px;
	animation-delay:0.9s;
}

#fountainG_4{
	left:191px;
	animation-delay:1.05s;
}

#fountainG_5{
	left:255px;
	animation-delay:1.2s;
}

#fountainG_6{
	left:319px;
	animation-delay:1.35s;
}

#fountainG_7{
	left:383px;
	animation-delay:1.5s;
}

#fountainG_8{
	left:446px;
	animation-delay:1.64s;
}



@keyframes bounce_fountainG{
	0%{
	transform:scale(1);
		background-color:rgb(250,112,97);
	}

	100%{
	transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}