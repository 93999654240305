.container-training {
  text-align: center;
  padding: 20px;
  column-count: 1;
}

#training {
  position: relative;
}

#training:before {
  clip-path: polygon(100% 0, -1% 100%, 100% 100%);
  height:600px;
  width:100%;
  position:relative;
  content:'';
  left: 0;
  z-index: -1;
  display:block;
  position:absolute;
  top: calc( 100% - 599px );
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(250,112,97,1) 100%);
}

@media (min-width: 576px) {
  .container-training {
    column-count: 2;
  }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .container-training {
    column-count: 3;
  }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .container-training {
    column-count: 4;
  }
 }